import {
  Box,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import useLocales from "src/hooks/useLocales";
import EmptyTable from "../emptyTable";
import Loop from "../loop";
import Ternary from "../ternary";
import { Currency } from "../with-prefix";
import { snakeCase } from "change-case";

const DataHandlerTable = ({
  dataProps,
  headers = [],
  children,
  tableProps = {},
  name,
}) => {
  const { translate } = useLocales();
  const { loading, error, isArrayEmpty } = dataProps;
  const checkIsAmount = (value) =>
    value === "Amount" ? `${value}${(<Currency />)}` : value;
  <Loop
    list={headers}
    render={(item) => {
      const translateValue = translate(item);
      const isAmount = snakeCase(translateValue) === "amount";

      return (
        <TableCell>
          {translateValue}
          {isAmount ? (
            <>
              (<Currency />)
            </>
          ) : null}
        </TableCell>
      );
    }}
  />;
  return (
    <TableContainer component={Paper}>
      <Table {...tableProps} name={name}>
        <TableHead>
          <TableRow>
            <Loop
              list={headers}
              render={(item) => {
                const translateValue = translate(item);
                const isAmount =
                  snakeCase(translateValue) === "amount" ||
                  snakeCase(translateValue) === "credit";
                return (
                  <TableCell>
                    {translateValue}
                    {isAmount ? (
                      <>
                        -<Currency />
                      </>
                    ) : null}
                  </TableCell>
                );
              }}
            />
          </TableRow>
        </TableHead>
        <TableBody>{children}</TableBody>
      </Table>
      <Ternary
        when={loading}
        then={
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              marginTop: 3,
              marginBottom: 3,
            }}
          >
            <CircularProgress />
          </Box>
        }
        otherwise={
          <Ternary when={isArrayEmpty} then={<EmptyTable error={error} />} />
        }
      />
    </TableContainer>
  );
};

export default DataHandlerTable;
