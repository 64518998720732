import { lazy } from "react";
import { Navigate } from "react-router";
import Loadable from "src/routes/Loadable";

const PackageRequest = Loadable(
  lazy(() => import("src/pages/admin/packageRequest/index"))
);

const Package = [
  {
    path: "package-request",
    children: [
      { index: true, element: <PackageRequest /> },
    ],
  },
];

export default Package;
