import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { capitalCase, constantCase, snakeCase } from "change-case";
import { upperCase } from "lodash";
import DataHandlerTable from "src/components/data-handler/table";
import EmptyTable from "src/components/emptyTable";
import Map from "src/components/map";
import PaginationButtons from "src/components/pagination";
import Scrollbar from "src/components/Scrollbar";
import useLocales from "src/hooks/useLocales";
import { BodyRow } from "src/pages/admin/reports/components";

const headers = [
  "userFinancial.eWallet.no",
  "userFinancial.eWallet.fromUser",
  "userFinancial.eWallet.amountType",
  "userFinancial.fundTransfer.paymentType",
  "userFinancial.eWallet.amount",
  "userFinancial.eWallet.date",
];

const DataList = ({ state, rowStart, ...rest }) => {
  const { data, ...dataProps } = state;
  const { translate } = useLocales();

  return (
    <>
      <Card sx={{ mt: 2 }}>
        <Scrollbar>
          <DataHandlerTable
            name="faq-table"
            headers={headers}
            dataProps={{ ...dataProps }}
          >
            <Map
              list={data}
              render={(item, i) => (
                <>
                  <TableRow key={item.id}>
                    <TableCell>{i + rowStart}</TableCell>
                    <TableCell>{item.from_user?.username}</TableCell>
                    <TableCell>
                      {snakeCase(item?.payment_type) === "roi"
                        ? upperCase(item?.payment_type)
                        : capitalCase(item?.payment_type)}
                    </TableCell>
                    <TableCell sx={{ textTransform: "capitalize" }}>
                      {item.type}
                    </TableCell>
                    <TableCell>{item.payable_amount}</TableCell>
                    <TableCell>
                      {new Date(item.created_at).toLocaleDateString("en-GB")}
                    </TableCell>
                  </TableRow>
                </>
              )}
            />
          </DataHandlerTable>
        </Scrollbar>
      </Card>
      <PaginationButtons {...rest} />
    </>
  );
};

export default DataList;
