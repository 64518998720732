import { lazy } from "react";
import { Navigate } from "react-router";
import { BinaryCheck } from "src/components/binary";
import Loadable from "src/routes/Loadable";

const Report = Loadable(lazy(() => import("src/pages/admin/reports/index")));
const FundReport = Loadable(
  lazy(() => import("src/pages/admin/reports/fund/index"))
);
const BuilderReport = Loadable(
  lazy(() => import("src/pages/admin/reports/builder/index"))
);
const PayoutReport = Loadable(
  lazy(() => import("src/pages/admin/reports/payout/index"))
);

const PointReport = Loadable(
  lazy(() => import("src/pages/admin/reports/point/index"))
);
const SalesReport = Loadable(
  lazy(() => import("src/pages/admin/reports/sales/index"))
);
const JoiningReport = Loadable(
  lazy(() => import("src/pages/admin/reports/joining/index"))
);

const IncomeReport = Loadable(
  lazy(() => import("src/pages/admin/reports/income/index"))
);

const TopEarnersReport = Loadable(
  lazy(() => import("src/pages/admin/reports/topEarners/index"))
);
const Invest = Loadable(
  lazy(() => import("src/pages/admin/reports/invest/index"))
);

const reports = [
  {
    path: "report",
    element: <Report />,
    children: [
      {
        element: <Navigate to="fund/credit" />,
        index: true,
      },
      // {
      //   path: "builder",
      //   element: (
      //     <BinaryCheck>
      //       <BuilderReport
      //         title="report.businessBuilderReport"
      //         heading="report.businessBuilderReport"
      //       />
      //     </BinaryCheck>
      //   ),
      // },
      {
        path: "fund/credit",
        element: (
          <FundReport
            title="report.fundTransferReport"
            heading="report.fundTransferReport"
          />
        ),
      },
      {
        path: "joining",
        element: (
          <JoiningReport
            title="report.joiningReport"
            heading="report.joiningReport"
          />
        ),
      },
      {
        path: "income",
        element: (
          <IncomeReport
            title="report.memberIncomeReport"
            heading="report.memberIncomeReport"
          />
        ),
      },
      {
        path: "payout",
        element: (
          <PayoutReport
            title="report.payoutReport"
            heading="report.payoutReport"
          />
        ),
      },

      // {
      //   path: "point/history",
      //   element: (
      //     <PointReport
      //       title="report.pointHistoryReport"
      //       heading="report.pointHistoryReport"
      //     />
      //   ),
      // },
      {
        path: "earners",
        element: (
          <TopEarnersReport
            title="report.topEarnersReport"
            heading="report.topEarnersReport"
          />
        ),
      },
      // {
      //   path: "sales",
      //   element: (
      //     <SalesReport
      //       title="report.salesReport"
      //       heading="report.salesReport"
      //     />
      //   ),
      // },
      {
        path: "invest-history",
        element: (
          <Invest title="investmentHistory" heading="investmentHistory" />
        ),
      },
    ],
  },
];

export default reports;
