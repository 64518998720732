const status = [
  { value: "open", name: "Open", icon: "bx:folder-open" },
  { value: "resolved", name: "Resolved", icon: "el:ok-circle" },
  { value: "closed", name: "Closed", icon: "ic:baseline-done-all" },
  { value: "archived", name: "Archived", icon: "bx:archive" },
  { value: "deleted", name: "Deleted", icon: "fluent:delete-12-regular" },
  { value: "unverified", name: "Unverified", icon: "octicon:unverified-24" },
  {
    value: "request_approval",
    name: "Request Approval",
    icon: "fluent:approvals-app-24-filled",
  },
  { value: "in_progress", name: "In Progress", icon: "mdi:progress-helper" },
  {
    value: "responded",
    name: "Responded",
    icon: "mdi:calendar-remove-outline",
  },
];

export default status;
