import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { capitalCase, snakeCase } from "change-case";
import { Currency } from "src/components/with-prefix";
import useLocales from "src/hooks/useLocales";

const TableHeader = ({ headers }) => {
  const { translate } = useLocales();

  return (
    <TableHead>
      <TableRow>
        {headers?.map((header, i) => {
          const translateValue = translate(header);
          const isAmount =
            snakeCase(translateValue) === "amount" ||
            snakeCase(translateValue) === "credit";
          return (
            <TableCell>
              {translateValue}
              {isAmount ? (
                <>
                  -<Currency />
                </>
              ) : null}
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};

const CustomTable = ({ headers, children }) => {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }}>
        <TableHeader headers={headers} />
        <TableBody>{children}</TableBody>
      </Table>
    </TableContainer>
  );
};

export default CustomTable;
