import PropTypes from "prop-types";
import { Navigate, Outlet } from "react-router-dom";
import useAuth from "src/hooks/useAuth";
import { PATH_DASHBOARD } from "src/routes/paths";

// LandingGuard.propTypes = {
//   children: PropTypes.node,
// };
const LandingGuard = ({children}) => {
  const { isAuthenticated, isAdmin } = useAuth();

  if (isAuthenticated) {
    return isAdmin ? <Navigate to="/admin" /> : <Navigate to="/user" />;
  }

  return <>{children}</>;
};

export default LandingGuard;
