import { Box, Grid, Stack, Typography } from "@mui/material";
import TicketAbout from "./about";
import TicketButton from "./buttonsTickets";
import DatesCard from "./dates";
import useGetTickets from "./hook/useGetTickets";
import ReplyForm from "./reply/replyForm";
import ReplyList from "./reply/replyList";
import useLocales from "src/hooks/useLocales";

const Index = () => {
  const { translate } = useLocales();
  const { ticketData, fetchTicket } = useGetTickets();
  const { ticket_number } = ticketData || {};

  return (
    <>
      <Box>
        <Grid container spacing={3}>
          <Grid item xs={12} md={5}>
            <Typography>
              {translate("latest.ticketId")} : <b>{ticket_number}</b>
            </Typography>
          </Grid>
          <Grid item xs={12} md={7}>
            <TicketButton ticketData={ticketData} fetchTicket={fetchTicket} />
          </Grid>
          <Grid item xs={12} md={4}>
            <Stack spacing={3}>
              <DatesCard ticketData={ticketData} />
              <TicketAbout ticketData={ticketData} />
            </Stack>
          </Grid>
          <Grid item xs={12} md={8}>
            <Stack spacing={3}>
              <ReplyList ticketData={ticketData} fetchTicket={fetchTicket} />
              <ReplyForm ticketData={ticketData} fetchTicket={fetchTicket} />
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Index;
