import { LoadingButton } from "@mui/lab";
import { Dialog, DialogContent, Stack, Typography } from "@mui/material";
import { FormProvider, RHFTextField } from "src/components/hook-form";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import axiosInstance from "src/utils/axios";
import { setSession } from "src/utils/jwt";
import { useSnackbar } from "notistack";

const defaultValues = {
  otp: "",
};

const schema = yup.object().shape({
  otp: yup.string().required("OTP is required"),
});
const EnterOtp = ({ open, setRegistrationComplete }) => {
  const methods = useForm({ defaultValues, resolver: yupResolver(schema) });
  const {
    formState: { isSubmitting },
  } = methods;
  const { enqueueSnackbar } = useSnackbar();
  const onSubmit = async (inputData) => {
    const reqData = new FormData();
    reqData.append("otp", inputData.otp);

    try {
      const { data, status } = await axiosInstance.post(
        "/api/registration-otp",
        reqData
      );
      setRegistrationComplete(false);
      const { user, token } = data;
      localStorage.setItem("isAdmin", Boolean(user.is_super_admin));
      localStorage.setItem("isSubAdmin", Boolean(user.is_sub_admin));
      setSession(token);
      window.location = "/";
    } catch (err) {
      methods.reset();
      enqueueSnackbar(err.message, { variant: "error" });
    }
  };
  return (
    <Dialog open={open}>
      <FormProvider methods={methods} onSubmit={methods.handleSubmit(onSubmit)}>
        <DialogContent>
          <Stack spacing={3}>
            <Typography variant="subtitle2" mb={2}>
              An OTP for email verification has been shared to your mail
            </Typography>
            <RHFTextField label="Enter OTP" type="number" name="otp" />
            <LoadingButton
              loading={isSubmitting}
              type="submit"
              variant="contained"
            >
              verify
            </LoadingButton>
          </Stack>
        </DialogContent>
      </FormProvider>
    </Dialog>
  );
};

export default EnterOtp;
