import { LoadingButton } from "@mui/lab";
import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  Typography,
} from "@mui/material";

import {
  FormProvider,
  RHFSelect,
  RHFTextField,
} from "src/components/hook-form";
import useFundForm from "./hooks/useFundForm";
import UsersList from "./usersList";
import useLocales from "src/hooks/useLocales";
import UsersSearch from "src/components/autoComplete/users";
import { useState } from "react";
import Iconify from "src/components/Iconify";
import axiosInstance from "src/utils/axios";
import { useSnackbar } from "notistack";

const SendForm = ({ refetch }) => {
  const { translate } = useLocales();
  const { methods, onSubmit } = useFundForm(refetch);
  const [showTransactionPassword, setShowTransactionPassword] = useState(false);
  const {
    formState: { isSubmitting },
  } = methods;
  const { enqueueSnackbar } = useSnackbar();
  const sendReqMail = async () => {
    try {
      const { data, status } = await axiosInstance.post(
        "/api/user/forgot-transaction-password"
      );
      if (status === 200) {
        enqueueSnackbar(data.message);
      }
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <>
      <Box sx={{ mt: 3 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
            <Typography variant="subtitle2">
              {translate("userFinancial.fundTransfer.sendForm")}
            </Typography>
            <FormProvider methods={methods} onSubmit={onSubmit}>
              <Box
                sx={{
                  display: "grid",
                  rowGap: 3,
                  columnGap: 2,
                  marginTop: 2,
                  gridTemplateColumns: {
                    xs: "repeat(1, 1fr)",
                    sm: "repeat(2, 1fr)",
                  },
                }}
              >
                <RHFSelect
                  name="wallet"
                  label={translate("userFinancial.fundTransfer.from")}
                  size="small"
                >
                  <option />
                  <option value="ewallet">
                    {translate("userFinancial.fundTransfer.e-Wallet")}
                  </option>
                  {/* <option value="deposit_wallet">
                    {translate("userFinancial.fundTransfer.depositWallet")}
                  </option> */}
                </RHFSelect>
                <UsersSearch name="user_id" props={{ size: "small" }} />

                {/* <UsersList /> */}
                <RHFTextField
                  type="number"
                  name="amount"
                  label={translate("userFinancial.fundTransfer.amount")}
                  size="small"
                  onWheel={(e) => e.target.blur()}
                />
                <RHFTextField
                  name="transaction_password"
                  label={translate("latest.transactionPassword")}
                  type={showTransactionPassword ? "text" : "password"}
                  size="small"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          edge="end"
                          onClick={() =>
                            setShowTransactionPassword(!showTransactionPassword)
                          }
                        >
                          <Iconify
                            icon={
                              showTransactionPassword
                                ? "eva:eye-fill"
                                : "eva:eye-off-fill"
                            }
                          />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>

              <Box
                sx={{
                  display: "grid",
                  rowGap: 3,
                  columnGap: 2,
                  marginTop: 2,
                  gridTemplateColumns: {
                    xs: "repeat(1, 1fr)",
                    sm: "repeat(1, 1fr)",
                  },
                }}
              >
                <RHFTextField
                  multiline
                  fullWidth
                  rows={3}
                  name="note"
                  label={translate("userFinancial.fundTransfer.note")}
                />
              </Box>
              <Box>
                <Link
                  // component={RouterLink}
                  variant="subtitle2"
                  // to={PATH_AUTH.resetPassword}
                  onClick={sendReqMail}
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    cursor: "pointer",
                    my: 2,
                  }}
                >
                  {translate("latest.forgotTransactionPassword")}
                </Link>
              </Box>
              <Box sx={{ display: "flex", mt: 2 }}>
                <LoadingButton
                  type="submit"
                  loading={isSubmitting}
                  variant="contained"
                  name="send"
                >
                  {translate("send")}
                </LoadingButton>
              </Box>
            </FormProvider>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default SendForm;
